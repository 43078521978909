<oiv-viewer-notification />
@if (isMobile()) {
    <oiv-viewer-overlay [isVisible]="isOpenOverlay()">
        <div class="mobi-ui-wrapper-content oiv-height-100">
            <div class="mobi-ui-container mobi-ui-padding-t-xl oiv-height-100">
                @if (avbChapters().length > 0) {
                    <ng-container>
                        <mobi-ui-split-button-group-major
                            class="split-button-search is-rounded"
                            [value]="majorValue()"
                            (valueChange)="handleMajorValueChange($event)"
                        >
                            <mobi-ui-split-button-major value="navigation">
                                <mobi-ui-icon-major name="menu" />
                                {{ 'oiv.navigation.splitButton.menu' | translate }}
                            </mobi-ui-split-button-major>
                            <mobi-ui-split-button-major value="index-search">
                                <mobi-ui-icon-major name="search_text" />
                                {{ 'oiv.navigation.splitButton.search' | translate }}
                            </mobi-ui-split-button-major>
                            @if (source() === 'B2E' && isAISearchFeatureActive()) {
                                <mobi-ui-split-button-major value="ai-search">
                                    <mobi-ui-icon-major name="message" />
                                    {{ 'oiv.navigation.splitButton.qa' | translate }}
                                </mobi-ui-split-button-major>
                            }
                        </mobi-ui-split-button-group-major>
                        <div class="overlay-split-button">
                            <oiv-viewer-navigation [hidden]="majorValue() !== NAVIGATION" />
                            <oiv-viewer-index-search [hidden]="majorValue() !== INDEX_SEARCH" />
                            @if (source() === 'B2E' && isAISearchFeatureActive()) {
                                <oiv-viewer-ai-search [hidden]="majorValue() !== AI_SEARCH" />
                            }
                        </div>
                    </ng-container>
                }
            </div>
        </div>
    </oiv-viewer-overlay>
}
<div class="mobi-ui-wrapper-content">
    <ng-container>
        @if (avbChapters().length > 0) {
            <div class="mobi-ui-container-overflow mobi-ui-flex oiv-height-100 position-relative">
                <div class="mobi-ui-container">
                    <div id="avb-content" class="mobi-ui-grid oiv-layout-custom mobi-ui-flex oiv-height-100">
                        @if (!isMobile()) {
                            <div
                                id="sticky-sidebar-wrapper"
                                class="xs:mobi-ui-block xs:mobi-ui-col-6/16 mobi-ui-section-minor is-extended-start mobi-ui-ink-grey mobi-ui-margin-x-none is-squared mobi-ui-margin-y-none mobi-ui-padding-x-none"
                            >
                                <div
                                    class="oiv-sticky mobi-ui-ink-default mobi-ui-padding-x-sm"
                                    id="oiv-sticky"
                                    [ngStyle]="sideBarStyleObject"
                                >
                                    <div class="xs:mobi-ui-block mobi-ui-padding-b-lg">
                                        <oiv-viewer-cover-sheet [coverSheet]="coverSheet()" />
                                    </div>
                                    <div class="split-button-group">
                                        <mobi-ui-split-button-group-major
                                            class="split-button-search is-rounded"
                                            [value]="majorValue()"
                                            (valueChange)="handleMajorValueChange($event)"
                                        >
                                            <mobi-ui-split-button-major value="navigation">
                                                <mobi-ui-icon-major name="menu" />
                                                {{ 'oiv.navigation.splitButton.menu' | translate }}
                                            </mobi-ui-split-button-major>
                                            <mobi-ui-split-button-major value="index-search">
                                                <mobi-ui-icon-major name="search_text" />
                                                {{ 'oiv.navigation.splitButton.search' | translate }}
                                            </mobi-ui-split-button-major>
                                            @if (source() === 'B2E' && isAISearchFeatureActive()) {
                                                <mobi-ui-split-button-major value="ai-search">
                                                    <mobi-ui-icon-major name="message" />
                                                    {{ 'oiv.navigation.splitButton.qa' | translate }}
                                                </mobi-ui-split-button-major>
                                            }
                                        </mobi-ui-split-button-group-major>
                                        <div class="oiv-scroll">
                                            <oiv-viewer-navigation [hidden]="majorValue() !== NAVIGATION" />
                                            <oiv-viewer-index-search [hidden]="majorValue() !== INDEX_SEARCH" />
                                            @if (source() === 'B2E' && isAISearchFeatureActive()) {
                                                <oiv-viewer-ai-search [hidden]="majorValue() !== AI_SEARCH" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="mobi-ui-col-1/1 xs:mobi-ui-col-10/16 mobi-ui-margin-none mobi-ui-padding-xl">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    class="mobi-ui-btn-icon-major oiv-back-to-top-button"
                    [class.show]="showScrollButton()"
                    (click)="scrollToTop()"
                >
                    <mobi-ui-icon-major name="up"></mobi-ui-icon-major>
                </button>
            </div>
        }
    </ng-container>
</div>
