<div class="mobi-ui-width-full" (click)="navigateToResult()">
    <div class="mobi-ui-space-between">
        <p
            class="mobi-ui-font-size-100 mobi-ui-margin-b-xs mobi-ui-font-italic"
            [innerHTML]="resultItem.breadcrumb"
        ></p>
        <a class="mobi-navigation-button">
            <mobi-ui-icon-major name="go_to_link" />
        </a>
    </div>
    <h5
        class="mobi-ui-text-inked mobi-ui-font-size-2xl mobi-ui-margin-b-xs mobi-ui-font-tisa"
        [innerHTML]="highlightSearchContent(resultItem.title)"
    ></h5>
    <p
        class="mobi-ui-font-size-md mobi-ui-margin-b-md"
        [innerHTML]="highlightSearchContent(resultItem.description)"
    ></p>
</div>
