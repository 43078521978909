import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TranslationService } from '@mobi/rwc-utils-ng-jslib';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { MobiUiMessageLevel } from '@mobi/rwc-ui-components-ng-jslib';

import { OivViewerService } from '../data/service/oiv-viewer.service';
import { MCM_BASE_URL, OIV_BASE_URL, OIV_QA_BASE_URL } from '../../../util/constant/common';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
    oivCoreSharedFacade = inject(OivCoreSharedFacade);
    translationService = inject(TranslationService);
    oivViewerService = inject(OivViewerService);
    BASE_URL_LIST: readonly string[] = [OIV_BASE_URL, OIV_QA_BASE_URL, MCM_BASE_URL];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(httpErrorResponse => this.handleError(httpErrorResponse)));
    }

    private handleError(httpErrorResponse: HttpErrorResponse) {
        if (this.isCheckedBaseUrl(httpErrorResponse.url)) {
            if (httpErrorResponse.url?.includes('chat_with_avb') && httpErrorResponse.status === 404) {
                this.handleReIndexing();
            }
            let messageKey = httpErrorResponse.url?.includes('chat_with_avb')
                ? httpErrorResponse.error.detail
                : httpErrorResponse.error?.errors?.[0]?.reason || 'oiv.error.general';
            if (httpErrorResponse.status === 403) {
                messageKey = 'oiv.error.forbidden';
            }
            this.translateAndDispatchError(messageKey);
        }
        return throwError(() => httpErrorResponse);
    }

    handleReIndexing() {
        this.oivViewerService.reIndexing();
    }

    isCheckedBaseUrl(url: string | null) {
        return this.BASE_URL_LIST.some(baseUrl => url?.toLowerCase().includes(baseUrl.toLowerCase()));
    }

    translateAndDispatchError(messageKey: any) {
        this.translationService.translate(messageKey).subscribe(translatedMessage => {
            this.oivCoreSharedFacade.addAlert(translatedMessage, MobiUiMessageLevel.ERROR);
        });
    }
}
