import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import {
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiPopoverComponent,
    MobiUiPopoverDirective,
    MobiUiTextareaComponent,
} from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconCopy, mobiIconEdit, mobiIconFaceWomanB } from '@mobi/rwc-utils-icons-jslib';

import { GPTMessageComponent } from '@mobi/gpt-chat-ng-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { Message } from '../../data/model/message.model';

@Component({
    selector: 'oiv-user-message',
    standalone: true,
    imports: [
        CommonModule,
        MobiUiIconComponent,
        MobiUiTextareaComponent,
        TranslatePipe,
        GPTMessageComponent,
        MobiUiPopoverComponent,
        MobiUiPopoverDirective,
    ],
    templateUrl: './user-message.component.html',
    styleUrl: './user-message.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMessageComponent {
    @Input({ required: true })
    message!: Message;

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconFaceWomanB, mobiIconEdit, mobiIconCopy]);
    }

    public copyToClipboard(): void {
        navigator.clipboard.writeText(this.message.content!);
        this.timeout();
    }

    private timeout() {
        setTimeout(() => document.body.click(), 1000);
    }
}
