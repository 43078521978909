import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';

import { Language, LanguageService, SPAConfiguration } from '@mobi/rwc-utils-ng-jslib';
import { OivCoreSharedFacade, PAGE_URL_PATH, RouteHelper, URL_CONST } from '@mobi/oiv-viewer-utils-ng-jslib';

import { LIST_LANGUAGE } from '../constant/common';

export function getAndSetStoredLanguageIfNecessary(userLanguage: Language) {
    const storedLanguage = localStorage.getItem('settings.language');
    if (storedLanguage) userLanguage = storedLanguage.replace(/"/g, '') as Language;
    return userLanguage;
}

export function setLocalStorageLanguage(languageParam: string) {
    const userLanguage = languageParam.replace(/"/g, '');
    localStorage.setItem('settings.language', userLanguage);
    return userLanguage as Language;
}

export function handleB2CConfig(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    RWC_CONFIG: SPAConfiguration,
    router: Router,
): Language {
    let userLanguage: Language = 'de';
    if (RWC_CONFIG?.stage === 'prod') {
        const currentUrl = window.location.hostname;
        const matchedUrl = URL_CONST.find(url => currentUrl.indexOf(url.domain) > -1);
        if (matchedUrl) userLanguage = matchedUrl.language as Language;
    } else {
        const languageParam = route.queryParams['language'];
        if (languageParam) {
            userLanguage = setLocalStorageLanguage(languageParam);
            const queryParams = { ...route.queryParams, language: null };
            router.navigate([state.url], { queryParams: queryParams, queryParamsHandling: 'merge' });
        } else {
            userLanguage = getAndSetStoredLanguageIfNecessary(userLanguage);
        }
    }
    return userLanguage;
}

// TODO: REFACTOR THE LOGIC FOR READABILITY
export function handleB2EConfig(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, router: Router): Language {
    let userLanguage: Language = 'de';
    const languageParam = route.params['language'];
    if (LIST_LANGUAGE.includes(languageParam)) {
        userLanguage = setLocalStorageLanguage(languageParam);
    } else if (
        !state.url.includes(PAGE_URL_PATH.NOT_FOUND) &&
        !state.url.includes(PAGE_URL_PATH.NOT_AVAILABLE) &&
        !state.url.includes(PAGE_URL_PATH.NO_PERMISSION)
    ) {
        const helper = inject(RouteHelper);
        router.navigateByUrl(`/${userLanguage}${helper.removeFirstPath(state.url)}`);
    } else if (state.url.includes(PAGE_URL_PATH.NOT_FOUND) || state.url.includes(PAGE_URL_PATH.NOT_AVAILABLE)) {
        userLanguage = getAndSetStoredLanguageIfNecessary(userLanguage);
    }
    return userLanguage;
}

export const languageResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void => {
    const languageService = inject(LanguageService);
    const router = inject(Router);
    const facade = inject(OivCoreSharedFacade);
    // We need this workaround to fix issue when we change the language by domain.
    // Subscribe to the language service to trigger the language change.
    // It will get the initial language then subscribe to the language change when we use languageService.use()
    // Happens only in B2C
    languageService.language$.subscribe();

    const RWC_CONFIG: SPAConfiguration = (window.document.defaultView as Window).MOBI_RWC_CONFIG;
    let userLanguage: Language = 'de';

    if (RWC_CONFIG?.infraPreset === 'b2c') {
        userLanguage = handleB2CConfig(route, state, RWC_CONFIG, router);
    } else if (RWC_CONFIG?.infraPreset === 'b2e') {
        userLanguage = handleB2EConfig(route, state, router);
    }

    // Set the language in the language service and the mobiUILanguageService
    facade.language = userLanguage;
    facade.updateLibLanguage(userLanguage);
};
