import {
    ChangeDetectionStrategy,
    Component,
    effect,
    inject,
    input,
    OnChanges,
    output,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
    MobiUiFormFieldComponent,
    MobiUiFormMessageComponent,
    MobiUiIconsRegistry,
    MobiUiInputComponent,
    MobiUiSplitButtonComponent,
    MobiUiSplitButtonGroupComponent,
} from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconSearch, mobiIconTheX } from '@mobi/rwc-utils-icons-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { SearchParam } from '../../model/search-param';

@Component({
    selector: 'oiv-viewer-search-box',
    standalone: true,
    imports: [
        CommonModule,
        MobiUiSplitButtonComponent,
        MobiUiSplitButtonGroupComponent,
        ReactiveFormsModule,
        MobiUiFormFieldComponent,
        MobiUiInputComponent,
        MobiUiFormMessageComponent,
        TranslatePipe,
    ],
    templateUrl: './search-box.component.html',
    styleUrl: './search-box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnChanges {
    keySearch = input<string>('');
    isDisabled = input<boolean>(false);
    searchTextChange = output<SearchParam>();
    #fb = inject(FormBuilder);
    searchForm: FormGroup = this.#fb.group({
        query: ['', [Validators.required]],
    });

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconSearch, mobiIconTheX]);
        this.searchForm
            ?.get('query')
            ?.valueChanges.pipe(debounceTime(500))
            .subscribe(value => {
                this.searchTextChange.emit({
                    text: value,
                } as SearchParam);
            });
        effect(
            () => {
                this.searchForm.get('query')?.setValue(this.keySearch(), { emitEvent: false });
            },
            { allowSignalWrites: true },
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isDisabled']) {
            this.searchForm.get('query')?.[this.isDisabled() ? 'disable' : 'enable'](); // Disable the input field if index is not READY
        }
    }

    clear(): void {
        this.searchForm.get('query')?.setValue('');
    }
}
