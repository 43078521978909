import { Component, inject } from '@angular/core';

import { MobiUiMessage, MobiUiMessageItemComponent, MobiUiMessagesComponent } from '@mobi/rwc-ui-components-ng-jslib';
import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

@Component({
    selector: 'oiv-viewer-notification',
    standalone: true,
    imports: [MobiUiMessagesComponent, MobiUiMessageItemComponent],
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.scss',
})
export class NotificationComponent {
    #coreSharedFacade = inject(OivCoreSharedFacade);

    notifications = this.#coreSharedFacade.notifications;

    removeMessage(messageForRemoval: MobiUiMessage) {
        this.#coreSharedFacade.removeAlert(messageForRemoval);
    }
}
