@if (keySearch()) {
    <div class="">
        <p class="oiv-info-color">{{ numberHits() }} {{ 'oiv.indexSearch.results' | translate }} "{{ keySearch() }}"</p>
        <ul class="mobi-ui-list-minor has-last-separator">
            @for (item of allResultItemsPerPage(); track item) {
                <li class="mobi-ui-padding-t-md">
                    <oiv-viewer-search-result-item [resultItem]="item"></oiv-viewer-search-result-item>
                </li>
            }
        </ul>
        @if (numberHits() > 0) {
            <mobi-ui-paginator-major
                [length]="numberHits()"
                [pageSize]="pageSize()"
                [hidePageSize]="true"
                [pageIndex]="currentPage()"
                (pageChange)="onChangePage($event)"
            >
            </mobi-ui-paginator-major>
        }
    </div>
}
